import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';


// import logo from '../../logo.svg';

import logo from '../../img/RAWPRO-logo-150.png';


import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
// import { solid, icon, regular, brands } from '@fortawesome/fontawesome-svg-core' // <-- import styles to be used

// import { icon, regular } from '@fortawesome/free-regular-svg-icons'

function OffcanvasExample() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEntrar = () => alert('Usuario o contraseña incorrectos');




  return (
    <>
    {/* <div className="Header">
    <header> */}
        {/* <header className="App-header"> */}
        {/* <img src={logo} className="App-logo" alt="logo" />
          <h1>RAW080</h1> */}
      {['md'].map((expand) => (
        <Navbar key={expand} bg="dark" variant="dark" expand={expand} className="">
          <Container fluid>
          
            <Navbar.Brand href="#" placement="end">
            <img
              src={logo}
              width="150"
              height="30"
              className="d-inline-block align-top"
              alt=""
            />{' '}
            {/* RAW080 */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              
               
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  RAWPRO | Photo Agency
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {/* <Nav.Link href="#action1">Inicio</Nav.Link> */}

                  {/* <FontAwesomeIcon icon={solid('user-secret')} />
<FontAwesomeIcon icon={regular('coffee')} />
<FontAwesomeIcon icon={icon({name: 'coffee', style: 'solid'})} />
<FontAwesomeIcon icon={brands('twitter')} /> */}



                  <Nav.Link href="#login" onClick={handleShow}><FontAwesomeIcon icon={faLock} /> Login</Nav.Link>
                  {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#login">
  Launch demo modal
</button> */}
                  {/* <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>

                

  
                
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

             



      ))}

      {/* </header>
      </div> */}

      <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>
          <img
              src={logo}
              width="150"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          {/* <FontAwesomeIcon icon={faLock} /> ZONA PRIVADA */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" placeholder="Email" />
        <Form.Text className="text-muted">
          {/* We'll never share your email with anyone else. */}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Contraseña</Form.Label>
        <Form.Control type="password" placeholder="Contraseña" />
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}
      {/* <Button variant="primary" type="submit">
        Aceptar
      </Button> */}

      


    </Form>
        
        </Modal.Body>
        {/* <Modal.Footer> */}
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <div  className="d-grid gap-2 md-3 px-3 pb-3">
          <Button variant="info" size="lg" onClick={handleEntrar}>
            Entrar
          </Button>
          </div>
        {/* </Modal.Footer> */}
      </Modal>
    </>

    




        {/* <!-- Modal --> */}
        <div className="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="loginLabel">Modal title</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>

    </>
  );
}

export default OffcanvasExample;