import React from 'react'

// import Content from './Content'

import Carrusel from '../visual/Carrusel'

import Carrusel2 from '../visual/Carrusel2'

// import OffcanvasExample from './Menu'

// import CollapsibleExample from './Menu'


// import HeaderModal from '../../componentes/HeaderModal'

function Contenido() {
  return (
    <div>

    {/* <HeaderModal /> */}

      <Carrusel />

      <Carrusel2 />

      {/* <Content /> */}

    </div>
  )
}

export default Contenido