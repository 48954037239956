import React from 'react';
import './App.css';
import Header from './components/visual/Header';
import Contenido from './components/visual/Contenido';
import Footer from './components/visual/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App">
      <Header />

        <Contenido />

      <Footer />
    </div>
  
  );
}

export default App;
