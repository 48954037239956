// import logo from '../../logo.svg';


function Footer() {
    return (
      <div className="Footer">
        <header className="App-footer pt-3 pb-3">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
          {/* <h1>Footer</h1> */}
          <h6>Todos los derechos reservados ©2024 | info@rawpro.net</h6>
        </header>
      </div>
    );
  }
  
  export default Footer;