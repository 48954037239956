// import logo from '../../logo.svg';
import OffcanvasExample from './Menu';


function Header() {
    return (
      <>
        <OffcanvasExample />
      </>
      // <div className="Header">
      //   <header className="App-header">
      //   <img src={logo} className="App-logo" alt="logo" />
      //     <h1>RAW080</h1>
          
      //   </header>
      // </div>
    );
  }
  
  export default Header;